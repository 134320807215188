<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'Saque': 'Saque',
      'Save': 'Salvar',
      'Type': 'Tipo',
      'Key': 'Chave',
      'The key is required.': 'A chave é obrigatório',
      'The type of the key is mandatory.': 'O tipo de chave é obrigatório'
    },
    es: {
      'Saque': 'Saque',
      'Save': 'Salvar',
      'Type': 'Escribe',
      'Key': 'llave',
      'The key is required.': 'Se requiere la clave',
      'The type of the key is mandatory.': 'El tipo de clave es obligatorio'
    }
  },
  components: { Layout },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      alert: {
        type: '',
        message: ''
      },

      loading: false,

      company: '',
      key: ''
    };
  },
  validations: {
    company: { required },
    key: { required }
  },
  methods: {
    getWithdraw() {
      api
      .get('user/withdraw')
      .then((response) => {
        if (response.data.status == 'success') {
          this.company = response.data.company,
          this.key = response.data.key
        }
      })
    },
    withdrawSubmit() {
      this.loading = true
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false
        return;
      } else {
        if (this.company && this.key) {
          api
          .post('user/withdraw', {
            company: this.company,
            key: this.key
          })
          .then(response => {
            if (response.data.status=='success') {
              this.$v.$reset()

              this.alert.type = 'alert-success'
              this.alert.message = 'A chave foi cadastrada com sucesso!'
            } else {
              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
            }

            this.loading = false
          })
          .catch(error => {
            if (error) {
              this.loading = false
            }
          })
        }
      }
    },
  },
  mounted() {
    this.getWithdraw()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-7">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Saque') }}</h4>
        </div>
        <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
        <div class="card">
          <div class="card-body table-responsive">
            <b-form class="p-3" @submit.prevent="withdrawSubmit">
              <b-form-group :label="t('Empresa')">
                <select v-model="company" class="custom-select mb-2">
                  <option value="">–</option>
                  <option value="Zelle">Zelle</option>
                  <option value="Venmo">Venmo</option>
                </select>
                <div v-if="$v.company.$error" class="invalid-feedback">
                  <span v-if="!$v.company.required">{{ t('The type of the key is mandatory.') }}</span>
                </div>
              </b-form-group>
              <b-form-group :label="t('Key')">
                <b-form-input v-model="key" type="text" :class="{ 'is-invalid': $v.key.$error }"></b-form-input>
                <div v-if="$v.key.$error" class="invalid-feedback">
                  <span v-if="!$v.key.required">{{ t('The key is required.') }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="this.loading == true" type="submit" variant="default">
                  {{ t('Save') }}
                  <b-spinner v-if="loading" small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>